<template>
  <div class="left-con left-arear" :class="{'is-open': isOpen}" @click.stop>
    <el-tree :data="areaList" :props="defaultProps" @node-click="handleNodeClick" default-expand-all>
      <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ data.areaName }}</span>
       </span>
    </el-tree>

    <img v-show="isOpen" @click.stop="close" class="left-arr" src="@/assets/img/county/left-arr.png" alt="" srcset="">
    <img v-show="!isOpen" @click.stop="open" class="right-arr flash-animate1" src="@/assets/img/county/right-arr.png" alt="" srcset="">
  </div>
</template>
<script>

export default {

  data () {
    return {
      isOpen: false,
      data: [],
      defaultProps: {
        children: 'schools',
        label: 'areaInfo.areaName'
      },
      areaList: []
    }
  },
  methods: {

    // 点击节点
    handleNodeClick (data, node) {

      // this.$store.commit('user/setCountyUserInfo', data)

      var obj = {
          organizationIdStr: data.eduId,
          organizationName: data.eduName
      }
      window.open(location.origin + '?countyUserInfo=' + JSON.stringify(obj) + '#/county')
    },

    close () {
      this.isOpen = false
    },

    open () {
      this.isOpen = true
    },

    async getAreaList () {
      const res = await this.http.get('/district-command-center/city/dataStats/district/tree')
      // const res = JSON.parse('{"msg":null,"data":{"cityAreaId":"350100","subEdus":[{"areaId":"350121","areaName":"闽侯县","eduName":"福建省福州市闽侯县教育局","eduId":"39fa74516dc842134064c7461e6c790c"},{"areaId":"350181","areaName":"福清市","eduName":"ljm测试教育局2","eduId":"39fb7e9085630c2a3e7c12e0d057a21f"}],"cityAreaName":"福州市","cityEduId":"350100","cityEduName":"福建省福州市教育局"},"status":200}')
      if (res.status !== 200) return
      this.areaList = res.data.subEdus
    }
  },

  mounted () {
    this.getAreaList()
  }
}
</script>
<style scoped>
.left-con{
  width: 2.5rem; height: 100%; background: rgba(0, 9, 30, 0.85);
  position: absolute;z-index: 10; top: 0; left: -2.5rem; box-sizing: border-box;
  transition: 0.5s; padding: 0.6rem 0.2rem 0 0.2rem;
  color: #fff;
}
.is-open{
  left: 0; overflow-y: scroll;
}
.left-arr{
  cursor: pointer; width: 0.2rem; height: 0.52rem; position: absolute; right: 0; top: 50%;
  transform: translateY(-50%);
}

.right-arr{
  cursor: pointer; width: 0.2rem; height: 0.52rem; position: absolute; right: -0.2rem; top: 50%;
  transform: translateY(-50%);
}
</style>
<style lang="scss">
.left-arear{
  .el-tree{
    background: none !important;
    color: #fff !important;

  }
  .el-tree-node__label{
    font-size: 0.16rem !important;
  }
  .el-tree-node__content{
    padding-top: 0.05rem; padding-bottom: 0.05rem;
  }
  .el-tree-node__content:hover {
    background:rgba(11, 89, 158, 1);
  }
  .el-tree-node:focus>.el-tree-node__content {
    background: rgba(11, 89, 158, 1);
  }
  .el-tree-node__expand-icon{
    color: rgba(64, 131, 191, 1);
  }
  .el-tree-node__expand-icon.is-leaf{
    color: transparent;
  }
}
</style>
